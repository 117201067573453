<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle text="Sign Up Your Club" icon="medal" />
    <div style="width: 100%; overflow-x: auto;flex-grow: 1;">
      <div
        class="text-left primary-colour mx-auto font-weight-light py-2"
        style="margin-top:20px;width: 100%; background: var(--bg-color) !important; z-index: 2;font-size: 0.9rem;"
      >
        <div>
          Please fill in the form below, you club will be registered and you will be added to the platform as your club's administrator enabling you to review your clubs
          earnings on a month by month basis. 
        </div>
      </div>
      <validation-observer>
        <form style="width:100%" autocomplete="off">
          <div class="md-form">
            <div class="md-form form-sm">
              <validation-provider
                mode="lazy"
                name="Club name"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.cnm"
                  type="text"
                  auto-complete="nc-cnm"
                  label="Club name"
                  style="margin-left:33px;max-width:100%;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Forename"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.fnm"
                  type="text"
                  auto-complete="nc-cnm"
                  label="First name"
                  style="margin-left:33px;max-width:100%;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Surname"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.lnm"
                  type="text"
                  auto-complete="nc-cnm"
                  label="Last name"
                  style="margin-left:33px;max-width:100%;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm">
              <validation-provider
                mode="lazy"
                name="Date of birth"
                class="d-flex align-items-center"
              >
                <i class="fas fa-birthday-cake prefix sm" style="left:0;margin-top:20px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.udd"
                  class="mb-0"
                  type="text"
                  auto-complete="nc-day"
                  label="DD"
                  style="margin-top: 8px;margin-left:33px;margin-right:10px;width:33%;float:left;"
                  size="sm"
                  maxlength="2"
                  :max="31"
                  :min="1"
                />
                <mdb-input
                  v-model="newClub.umm"
                  class="mb-0"
                  type="text"
                  auto-complete="nc-mth"
                  label="MM"
                  style="margin-top: 8px;margin-right:10px;width:33%;float:left;"
                  size="sm"
                  maxlength="2"
                  :min="1"
                  :max="12"
                />
                <mdb-input
                  v-model="newClub.uyy"
                  class="mb-0 mobile-date"
                  type="text"
                  auto-complete="nc-yer"
                  label="YYYY"
                  maxlength="4"
                  style="margin-top: 8px;width:33%;float:left;"
                  size="sm"
                  :min="1900"
                  :max="2022"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Address"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.adr"
                  type="text"
                  auto-complete="nc-adr"
                  label="Address"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Postcode"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.pce"
                  type="text"
                  auto-complete="nc-pce"
                  label="Post code"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Email"
                rules="required"
              >
                <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.eml"
                  type="email"
                  auto-complete="nc-eml"
                  label="Main contact email"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:35px;">
              <validation-provider
                mode="lazy"
                name="Telephone"
                rules="required"
              >
                <i class="fas fa-phone prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
                <mdb-input
                  v-model="newClub.tel"
                  type="text"
                  auto-complete="nc-eml"
                  label="Main contact telephone"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div
              class="md-form form-sm"
              style="margin-top:35px;"
              @click="toggleAgree()"
            >
              <mdb-input
                v-model="agree"
                type="checkbox"
                auto-complete="nc-agr"
                style="margin-left:-20px;"
                size="sm"
              />
              <div style="margin-left: 33px;font-size: clamp(0.7rem, 1.5vw, 0.9rem);margin-top: -16px;color: white;">
                I agree to the terms and conditions and confirm I am 18 years or older and have have authorisation on behalf or my club to register an account.
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
      <div
        style="margin-top:20px;padding-bottom: 60px;"
        class="mr-4 mr-md-5"
        :style="{'float': mobileView ? 'right' : 'left'}"
      >
        <mdb-btn
          v-show="!buttonClick && !tickShowing"
          :disabled="!formIsValid"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          @click="clubSignUp()"
        >
          SIGN UP
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick && !tickShowing"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
        <mdb-btn
          v-if="tickShowing"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        >
          <mdb-icon icon="fa fa-check" />
        </mdb-btn>
        <div v-if="errorMessage != ''" class="mt-3 font-weight-bold">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'

export default {
  name: 'SignUp',
  components: {
    LeftTopPanelTitle
  },
  props: {
  },
  data () {
    return {
      newClub: {
        cnm: '',
        adr: '',
        pce: '',
        fnm: '',
        lnm: '',
        eml: '',
        tel: '',
        lll: 'TO BE CONFIRMED',
        udd: '',
        umm: '',
        uyy: '',
        aff: ''
      },
      buttonClick: false,
      agree: false,
      tickShowing: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'mobileView'
    ]),
    formIsValid () {
      return Object.values(this.newClub).every(x => x !== '') && this.agree
    }
  },
  created () {
    const affiliateCode = this.$route.query.aff
		if (affiliateCode !== null) {
			this.newClub.aff = affiliateCode
		}
  },
  mounted () {
  },
  methods: {
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    clubSignUp () {
      this.buttonClick = true
      baseService.clubSignUp(this.newClub).then(() => {
        setTimeout(() => {
          this.buttonClick = false
          this.tickShowing = true
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'THANK YOU', errorMessage: 'Thank you for registering your club. You will shortly receive a welcome email with a temporary password to access and manage your account. We will also contact you direct if you require any help to get your club and your supporters up and running.' })
          setTimeout(() => {
            this.$router.push({ path: '/play', query: { page: 1 } })
          }, 10000)
        }, 3000)
      }).catch((error) => {
        this.errorMessage = Object.assign({}, error).response.data.Message
        setTimeout(() => { this.errorMessage = '' }, 3000)
        this.buttonClick = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
