<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <SignUpYourClub />
    </template>

    <template #rightPanel>
      <div class="d-flex align-items-center justify-content-center p-4" style="height: 100%">
        <img
          style="width: 100%"
          src="@/assets/mobile-logo.png"
          @click="$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})"
        >
      </div>
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import MainContainer from '@/components/layout/mainContainer.vue'
import SignUpYourClub from '@/components/signUpYourClub/signUpYourClub.vue'

export default {
  name: 'SignUpYourClubContainer',
  components: {
    MainContainer, SignUpYourClub
  },
  computed: {
    ...mapGetters(['userData'])
  }
}
</script>
